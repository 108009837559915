import React from "react"

class MakeProfite extends React.Component {

  render() {
    return (
      <section className="fine-box crypapp pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Make Profitable Revenue</span> With Our Cryptocurrency Application Development Services 
            </h2>
            <p className="text-center mb-2">
            Our team focuses on crafting the cryptocurrency app with higher profit-reaping revenue streams.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Trading Fee</h3>
                <p className="pharagraph">
                You can charge transaction fees for each successful trading of the crypto user. Set a certain amount before the launch of the cryptocurrency app. We help you to analyze your competitors to frame the revenue charges.
                </p>
              </div>
              <div className="square" >
                <h3>Listing Fee</h3>
                <p className="pharagraph">
                In order to list the cryptocurrencies on the platform, users will pay an amount in the name of ‘listing fees’. Users who want more exposure and supply circulation for their cryptocurrency will pay this fee.
                </p>
              </div>
              <div className="square" >
                <h3>Subscription Fee</h3>
                <p className="pharagraph">
                If you tend to provide extraordinary features, offers, and gift cards for users, you can allocate them as premium offers. To utilize those, your users first need to subscribe to these features with a certain fee.
                </p>
              </div>
              <div className="square" >
                <h3>Launchpad Fee</h3>
                <p className="pharagraph">
                You can collect fees for launching the crypto crowdfunding projects of ICO, IEO, IDO, etc for token promotions and sales. Based on the marketing tactics, strategies, and promotional methodologies, you can fix the fee.
                </p>
              </div>
              <div className="square" >
                <h3>Margin Trading Fee</h3>
                <p className="pharagraph">
                Some exchanges offer margin trading, helping users to borrow funds for cryptocurrency transactions. You can collect interest, application fees, and transaction fees from crypto investors. 
                </p>
              </div>
              <div className="square" >
                <h3>Deposit/Withdrawal Fee</h3>
                <p className="pharagraph">
                For withdrawing or depositing cryptocurrencies from the platform, you can collect pre-destined amounts from crypto users. Even for converting fiat to crypto and vice versa you can charge fees.
                </p>
              </div>
            </div>
            <div className="mt-3 text-center">
              <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Launch your Crypto app with us</a>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default MakeProfite
